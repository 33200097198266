<template>
  <el-container class="patroladd">
    <el-header class="box add bb">
       <el-breadcrumb separator-class="el-icon-arrow-right">
         <el-breadcrumb-item>监管部门</el-breadcrumb-item>
         <el-breadcrumb-item>监管部门列表</el-breadcrumb-item>
        <el-breadcrumb-item v-if="form.department_id">编辑监管部门</el-breadcrumb-item>
         <el-breadcrumb-item v-else>新增监管部门</el-breadcrumb-item>
          </el-breadcrumb>
      <div>
        <el-button class="back_btn" size="medium" plain @click="$router.back()">返回</el-button>
      </div>
    </el-header>
    <el-main class="">
      <el-form :inline="true" status-icon ref="form" :model="form" class="demo-ruleForm">
        <el-row class="box mt20 f14">
          <el-col :span="11" class="tl">
            <el-form-item
              class="w"
              label-width="28%"
              label="监管部门名称："
              size="medium"
              prop="department_name"
              :rules="[{required: true,message: '必填项', trigger: 'blur' }]"
            >
              <el-input
                placeholder="监管部门名称"
                v-model="form.department_name"
                class="input-with-select"
              ></el-input>
            </el-form-item>
          </el-col>

          <el-col :span="22" class="tl" >
            <el-row class="box mt20 f14">
              <el-form-item class="w" label-width="14%" label="所在地址：" size="medium">
                <el-col :span="4">
                  <el-form-item size="medium">
                    <el-select clearable
                      v-model="provinceTemp"
                      placeholder="请选择省级"
                      @change="handleChange($event,2)"
                      value-key="region_name"
                    >
                      <el-option
                        v-for="item in provinceOptions"
                        :key="item.region_code"
                        :label="item.region_name"
                        :value="item"
                      ></el-option>
                    </el-select>
                  </el-form-item>
                </el-col>
                <el-col :span="4">
                  <el-form-item size="medium">
                    <el-select clearable
                      v-model="cityTemp"
                      placeholder="请选择市级"
                      @change="handleChange($event,3)"
                      value-key="region_name"
                    >
                      <el-option
                        v-for="item in cityOptions"
                        :key="item.region_code"
                        :label="item.region_name"
                        :value="item"
                      ></el-option>
                    </el-select>
                  </el-form-item>
                </el-col>
                <el-col :span="4">
                  <el-form-item size="medium">
                    <el-select clearable
                      v-model="areaTemp"
                      placeholder="请选择区/县"
                      @change="handleChange($event,4)"
                      value-key="region_name"
                    >
                      <el-option
                        v-for="item in areaOptions"
                        :key="item.region_code"
                        :label="item.region_name"
                        :value="item"
                      ></el-option>
                    </el-select>
                  </el-form-item>
                </el-col>
                <el-col :span="4">
                  <el-form-item size="medium">
                    <el-select clearable
                      v-model="townTemp"
                      placeholder="请选择街道/镇"
                      @change="handleChange($event,5)"
                      value-key="region_name"
                    >
                      <el-option
                        v-for="item in townOptions"
                        :key="item.region_code"
                        :label="item.region_name"
                        :value="item"
                      ></el-option>
                    </el-select>
                  </el-form-item>
                </el-col>
                <el-col :span="4">
                  <el-form-item size="medium">
                    <el-select clearable
                      v-model="villTemp"
                      placeholder="请选择居委会/村"
                      value-key="region_name"
                      @change="handleChange($event,6)"
                    >
                      <el-option
                        v-for="item in villOptions"
                        :key="item.region_code"
                        :label="item.region_name"
                        :value="item"
                      ></el-option>
                    </el-select>
                  </el-form-item>
                </el-col>
              </el-form-item>
            </el-row>
          </el-col>

          <el-col :span="22" class="tl" >
            <el-form-item class="w" label-width="14%" label="管理区域：" size="medium">
                <el-col :span="4">
                  <el-form-item size="medium">
                    <el-select clearable
                      v-model="provinceTemp1"
                      placeholder="请选择省级"
                      @change="handleChange($event,7)"
                      value-key="region_name"
                    >
                      <el-option
                        v-for="item in provinceOptions1"
                        :key="item.region_code"
                        :label="item.region_name"
                        :value="item"
                      ></el-option>
                    </el-select>
                  </el-form-item>
                </el-col>
                <el-col :span="4">
                  <el-form-item size="medium">
                    <el-select clearable
                      v-model="cityTemp1"
                      placeholder="请选择市级"
                      @change="handleChange($event,8)"
                      value-key="region_name"
                    >
                      <el-option
                        v-for="item in cityOptions1"
                        :key="item.region_code"
                        :label="item.region_name"
                        :value="item"
                      ></el-option>
                    </el-select>
                  </el-form-item>
                </el-col>
                <el-col :span="4">
                  <el-form-item size="medium">
                    <el-select clearable
                      v-model="areaTemp1"
                      placeholder="请选择区/县"
                      @change="handleChange($event,9)"
                      value-key="region_name"
                    >
                      <el-option
                        v-for="item in areaOptions1"
                        :key="item.region_code"
                        :label="item.region_name"
                        :value="item"
                      ></el-option>
                    </el-select>
                  </el-form-item>
                </el-col>
                <el-col :span="22">
                    <template v-if="townOptions1.length>0">
                <el-checkbox
                  :indeterminate="isIndeterminate"
                  v-model="checkAll"
                  @change="handleCheckAllChange"
                >全选</el-checkbox>
                <div style="margin: 15px 0;"></div>
                <el-checkbox-group v-model="checkedList" @change="handleCheckedChange">
                  <el-checkbox
                    v-for="(town,index) in townOptions1"
                    :label="town.region_name"
                    :key="index"
                  >{{town.region_name}}</el-checkbox>
                </el-checkbox-group>
              </template>
              <span v-else>至少选择区县后显示</span>
                </el-col>
              </el-form-item>
            <!-- <el-form-item class="w" label-width="14%" label="管理区域：" size="medium">
              
            </el-form-item> -->
          </el-col>
          <el-col :span="22" class="tl" :offset="1">
           <el-button class size="medium" type="primary" @click="submitForm('form')">提交</el-button>
        </el-col>
        </el-row>
      </el-form>
    </el-main>
  </el-container>
</template>
<script>
import axios from "../../../util/api";
import { local,session } from "../../../util/util";

export default {
  data() {
    return {
      form: {
        department_id: "",
        department_name: "",
        province: "",
        city: "",
        area: "",
        town: "",
        vill: "",
        region_list:[]
      },
      department1: {},
      department: {},
      regionList: [],
      provinceTemp: "",
      cityTemp: "",
      areaTemp: "",
      townTemp: "",
      villTemp: "",
      provinceTemp1: "",
      cityTemp1: "",
      areaTemp1: "",
      townTemp1: "",
      provinceOptions: [],
      cityOptions: [],
      areaOptions: [],
      provinceOptions1: [],
      cityOptions1: [],
      areaOptions1: [],
      townOptions1: [],
      townOptions: [],
      villOptions: [],
      userData: {},
      checkAll: false,
      checkedList: [],
      isIndeterminate: false,
      department_type:0,
      user:{}
    };
  },
  async mounted() {
    this.user = local.get("user");
    this.form.department_id =session.get('params').id?String(session.get('params').id):'';
    this.userData.user_type =3;
    this.department_type = this.user.department_type;
    await this.init({ department_id:this.form.department_id});
  },
  methods: {
    init(params) {
         if(this.form.department_id){
          axios.get("/pc/department/single", params).then(response => {
           this.department = response.data.department;
           this.form.department_id = response.data.department.department_id;
            this.form.department_name = response.data.department.department_name;
            this.regionList = response.data.region_list;
            this.department1=response.data.region_list[0];
            this.handleUserLevel(this.userData, this.department);
            this.handleUserLevel1(this.userData, this.department1);
      });
    
       }else{
       this.handleUserLevel(this.userData,'');this.handleUserLevel1(this.userData,'');}
       
      
    },
    async handleUserLevel(userData, department) {
      if (parseInt(userData.user_type) > 0 || department.province) {
        this.provinceOptions = await this.fetchRegionData(1);
        let province = this.provinceOptions.find(
          item =>item.region_name ==(department.province ? department.province :userData.user_province)
        );
        this.provinceTemp = province;
        if (parseInt(userData.user_type) >= 1 || department.city) {
          this.cityOptions = await this.fetchRegionData(
            2,
            province.region_code
          );
          let city = this.cityOptions.find(
            item =>
              item.region_name ==
              (userData.user_city ? userData.user_city : department.city)
          );
          if (parseInt(userData.user_type) >= 2 || department.area) {
            this.areaOptions = await this.fetchRegionData(3, city.region_code);
            let area = this.areaOptions.find(
              item =>
                item.region_name ==
                (userData.user_area ? userData.user_area : department.area)
            );
            this.cityTemp = city;
            if (parseInt(userData.user_type) >= 3 || department.town) {
              this.townOptions = await this.fetchRegionData(
                4,
                area.region_code
              );
              let town = this.townOptions.find(
                item =>
                  item.region_name ==
                  (userData.user_town ? userData.user_town : department.town)
              );
              this.areaTemp = area;
              if (parseInt(userData.user_type) >= 4 || department.vill) {
                this.villOptions = await this.fetchRegionData(
                  5,
                  town.region_code
                );
                let vill = this.villOptions.find(
                  item =>
                    item.region_name ==
                    (userData.user_vill ? userData.user_vill : department.vill)
                );
                this.townTemp = town;
                if (parseInt(userData.user_type) >= 5 || department.vill) {
                  this.villTemp = vill;
                }
              }
            }
          }
        }
      }
      
      // this.checkedList = this.regionList.map(item => item.region_town);
      // this.handleCheckedChange(this.checkedList);
    },
    async handleUserLevel1(userData, department) {
      if (parseInt(userData.user_type) > 0 || department.region_province) {
        this.provinceOptions1 = await this.fetchRegionData(1);
        let province1 = this.provinceOptions1.find(
          item =>item.region_name ==(department.region_province ? department.region_province :userData.user_province)
        );
        this.provinceTemp1 = province1;
        if (parseInt(userData.user_type) >= 1 || department.region_city) {
          this.cityOptions1 = await this.fetchRegionData(
            2,
            province1.region_code
          );
          let city1 = this.cityOptions1.find(
            item =>
              item.region_name ==
              (userData.user_city ? userData.user_city : department.region_city)
          );
          this.cityTemp1 = city1;
          if (parseInt(userData.user_type) >= 2 || department.region_area) {
            this.areaOptions1 = await this.fetchRegionData(3, city1.region_code);
            let area1 = this.areaOptions1.find(item =>item.region_name ==(userData.user_area ? userData.user_area : department.region_area));
            
            if (parseInt(userData.user_type) >= 3 || department.region_town) {
              this.townOptions1 = await this.fetchRegionData(4,area1.region_code);
              this.areaTemp1 = area1;
            }
          }
        }
      }
     
      this.checkedList = this.regionList.map(item => item.region_town);
      this.handleCheckedChange(this.checkedList);
    },
    fetchRegionData(type, region_high_code) {
      
      let region_type = type.toString();
      if(region_type==7){region_type='2'}else if(region_type==8){region_type='3'}else if(region_type==9){region_type='4'}else if(region_type==10){region_type='5'}
      return new Promise((resolve, reject) => {
        axios
          .get("/pc/region/all", { region_type, region_high_code })
          .then(response => {
            resolve(response.data.region_list);
          })
          .catch(err => {
            reject(err);
          });
      });
    },
    async handleChange(item, type) {
      let regionData;
      if (item.region_code == undefined) {
        regionData = '';
        if(type==7||type==8||type==9){
           this.checkedList=[]
        }
      } else {
        regionData = await this.fetchRegionData(type, item.region_code);
      }
      switch (type) {
        case 2:
          this.cityOptions = regionData;
          this.areaOptions = [];
          this.townOptions = [];
          this.villOptions = [];
          this.cityTemp = "";
          this.areaTemp = "";
          this.townTemp = "";
          this.villTemp = "";
          break;
        case 3:
          this.areaOptions = regionData;
          this.townOptions = [];
          this.villOptions = [];
          this.areaTemp = "";
          this.townTemp = "";
          this.villTemp = "";
          break;
        case 4:
          this.townOptions = regionData;
          this.villOptions = [];
          this.townTemp = "";
          this.villTemp = "";
          break;
        case 5:
          this.villOptions = regionData;
          this.form.town = item.region_name;
          this.villTemp = "";
          break;
        case 6:
          this.form.vill = item.region_name;
          break;
        case 7:
          this.cityOptions1 = regionData;
          this.areaOptions1 = [];
          this.townOptions1 = [];
          this.cityTemp1 = "";
          this.areaTemp1 = "";
          this.townTemp1 = "";
          break;
        case 8:
          this.areaOptions1 = regionData;
           this.townOptions1 = [];
           this.townTemp1 = "";
          this.areaTemp1 = "";
          break;
        case 9:
          this.townOptions1 = regionData;
           break;
      }
    },
    handleCheckAllChange(val) {
      this.checkedList = val
        ? this.townOptions1.map(item => item.region_name)
        : [];
      this.isIndeterminate = false;
    },
    handleCheckedChange(value) {
      let checkedCount = value.length;
      this.checkAll = checkedCount === this.townOptions1.length;
      this.isIndeterminate =
        checkedCount > 0 && checkedCount < this.townOptions1.length;
    },
    submitForm() {
      var that=this;
      
      this.form.region_list=[];
      if(this.checkedList.length>1){
        this.townOptions1.forEach(item=>{
         this.checkedList.forEach(item1=>{
             if(item.region_name==item1){
                this.form.region_list.push({
                  region_area:that.areaTemp1.region_name,
                  region_city:that.cityTemp1.region_name,
                  region_province:that.provinceTemp1.region_name,
                  region_town:item.region_name
                  })
             }
       })
      })
      }else{
        this.form.region_list=[{
                  region_area:that.areaTemp1?that.areaTemp1.region_name:'',
                  region_city:that.cityTemp1?that.cityTemp1.region_name:'',
                  region_province:that.provinceTemp1?that.provinceTemp1.region_name:"",
                  region_town:''
                  }]
      }
      this.form.department_id = String(this.form.department_id);
      this.form.province = this.provinceTemp?this.provinceTemp.region_name:'';
      this.form.city = this.cityTemp?this.cityTemp.region_name:'';
      this.form.area = this.areaTemp?this.areaTemp.region_name:'';
      this.form.town = this.townTemp?this.townTemp.region_name:'';
      this.form.vill = this.villTemp?this.villTemp.region_name:'';
       let params={
           department_id:String(this.form.department_id),
           department_name:this.form.department_name,
           province:this.form.province,
           city:this.form.city,
           area:this.form.area,
           town:this.form.town,
           vill:this.form.vill,
           type:0,
       }
      if(this.department_type==1)
         params.type = 1;
      axios.get("/pc/department/exist",params).then(v => {
            if(this.form.department_id){
          axios.put("/pc/department/update", this.form).then(v => {
          this.$router.back();
        });
       }else{
          axios.post("/pc/department/save", this.form).then(v => {
          this.$router.back();
        });
       }
       
      });
    }
  }
};
</script>
<style lang="scss" scoped>
.patroladd {
  overflow-x: hidden;
  .add {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .el-image {
    width: 160px;
    height: 160px;
    margin-right: 10px;
  }
  .back_btn {
    background: rgba(247, 248, 249, 1);
    border: 1px solid rgba(217, 222, 225, 1);
  }
  .patroladd-title {
    height: 34px;
    line-height: 34px;
    border-left: 2px solid #788287;
  }
  /* .el-form-item--medium .el-form-item__content{width: 80%} */
}
</style>
